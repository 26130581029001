import React from 'react';
//import ReactGA from 'react-ga';

//ReactGA.initialize('UA-000000-01');
//ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div className="App">
        <p>
          Havealmanakken.dk
        </p>
    </div>
  );
}

export default App;
